import React, { Fragment, useEffect, useState } from "react";
import "./LoginSignUp.css";
import Loader from "../layout/Loader/Loader.js";
import { useLocation, useHistory } from "react-router-dom";
import OTPDialog from "./OtpDialog.js";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, login, register } from "../../actions/userActions.js";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useAlert } from "react-alert";
import signin from "../../images/signin.png";
import signup from "../../images/signup.png";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const LoginSignUp = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const alert = useAlert();
  const [activeTab, setActiveTab] = useState("login");
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);
  const { error, loading, isAuthenticated } = useSelector(
    (state) => state.user
  );

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [hasAgreed, setHasAgreed] = useState(false);
  const [load, setLoad] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [user, setUser] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    serial: "",
    password: "",
    confirm: "",
  });

  const { name, company, email, phone, tally, password, confirm } = user;

  const loginSubmit = (e) => {
    e.preventDefault();
    dispatch(login(loginEmail, loginPassword));
  };

  const registerSubmit = (e) => {
    e.preventDefault();
    const myForm = new FormData();
    myForm.set("name", name);
    myForm.set("company", company);
    myForm.set("email", email);
    myForm.set("phone", phone);
    myForm.set("tally", tally);
    myForm.set("password", password);

    if (!hasAgreed) {
      alert.error("Please agree to the terms and conditions");
      return;
    }
    if (password !== confirm) {
      alert.error("Passwords do not match");
      return;
    }
    setOtpDialogOpen(true);

    sendOTP();
  };

  const sendOTP = async () => {
    try {
      setLoad(true);
      const config = { headers: { "Content-Type": "application/json" } };
      const response = await axios.post(`/api/v1/sendotp`, { phone }, config);

      if (response.data.message === "pending") {
        alert.success("Check your Phone");
      } else {
        alert.error(response.data.message);
      }
    } catch (error) {
      alert.error(error.message);
    } finally {
      setLoad(false);
    }
  };

  const handleOtpVerify = async (otp) => {
    try {
      setLoad(true);
      const config = { headers: { "Content-Type": "application/json" } };
      // Make an HTTP request to your backend to verify the OTP
      const response = await axios.post(
        `/api/v1/verifyotp`,
        { phone, otp },
        config
      );
      if (response.data.success === true) {
        alert.success("OTP verified successfully");

        setOtpDialogOpen(false);

        dispatch(register(user));
      } else {
        alert.error(response.data.message);
      }
    } catch (error) {
      alert.error("Failed to verify OTP:", error.message);
    } finally {
      setLoad(false); // Set loading state to false regardless of success or failure
    }
  };

  const handleOtpDialogClose = async () => {
    setOtpDialogOpen(false);
  };

  const setPhone = (value) => {
    setUser((prevUser) => ({ ...prevUser, phone: value }));
  };

  const registerDataChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const redirect = location.search
    ? location.search.split("=")[1]
    : "/user/profile";

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (isAuthenticated) {
      history.push(redirect);
    }
  }, [dispatch, error, alert, history, isAuthenticated, redirect]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="App">
            <div className="appAside">
              <div className="illustrate">
                <h2>
                  {activeTab === "login"
                    ? "Welcome Back! Log in to Your Account"
                    : "Wellcome To TallyAddons!"}
                </h2>
                <img
                  src={activeTab === "login" ? signin : signup}
                  alt="illustrate"
                />
              </div>
            </div>
            <div className="appForm">
              <div className="pageSwitcher">
                <div
                  className={`pageSwitcherItem ${
                    activeTab === "login" ? "pageSwitcherItem-active" : ""
                  }`}
                  onClick={() => setActiveTab("login")}
                >
                  Account Login
                </div>
                <div
                  className={`pageSwitcherItem ${
                    activeTab === "createAccount"
                      ? "pageSwitcherItem-active"
                      : ""
                  }`}
                  onClick={() => setActiveTab("createAccount")}
                >
                  Create Account
                </div>
              </div>

              {activeTab === "login" && (
                <div className="formCenter">
                  <form className="formFields" onSubmit={loginSubmit}>
                    <div className="formField">
                      <label className="formFieldLabel" htmlFor="email">
                        E-Mail Address
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="formFieldInput"
                        placeholder="Enter your email"
                        name="email"
                        value={loginEmail}
                        onChange={(e) => setLoginEmail(e.target.value)}
                      />
                    </div>

                    <div className="formField">
                      <label className="formFieldLabel" htmlFor="password">
                        Password
                      </label>

                      <div className="password-input-container">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          className="formFieldInput"
                          placeholder="Enter your password"
                          name="password"
                          value={loginPassword}
                          onChange={(e) => setLoginPassword(e.target.value)}
                        />

                        <IconButton
                          onClick={toggleShowPassword}
                          edge="end"
                          style={{ marginTop: "5px", color: "#555abf" }}
                        >
                          {!showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </div>
                      <div className="forgetPassword">
                        <a href="/user/profile/password/forgot">
                          <p>Forgot Password?</p>
                        </a>
                      </div>
                    </div>

                    <div className="formFieldButtonParent">
                      <button className="formFieldButton" type="submit">
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              )}

              {activeTab === "createAccount" && (
                <div className="formCenter">
                  <form className="formFields" onSubmit={registerSubmit}>
                    <div className="formField">
                      <label className="formFieldLabel" htmlFor="name">
                        Full Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="formFieldInput"
                        placeholder="Enter your full name"
                        name="name"
                        value={name}
                        onChange={registerDataChange}
                      />
                    </div>
                    <div className="formField">
                      <label className="formFieldLabel" htmlFor="name">
                        Company Name
                      </label>
                      <input
                        type="name"
                        id="company"
                        className="formFieldInput"
                        placeholder="Enter your company name"
                        name="company"
                        value={company}
                        onChange={registerDataChange}
                      />
                    </div>
                    <div className="formField">
                      <label className="formFieldLabel" htmlFor="email">
                        E-Mail Address
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="formFieldInput"
                        placeholder="Enter your email address"
                        name="email"
                        value={email}
                        onChange={registerDataChange}
                      />
                    </div>
                    <div className="formField">
                      <label className="formFieldLabel" htmlFor="tel">
                        Contact Number
                      </label>

                      <PhoneInput
                        id="phone"
                        defaultCountry="IN"
                        placeholder="Enter your contact number"
                        value={phone}
                        onChange={(value) => setPhone(value)}
                      />
                    </div>

                    <div className="formField">
                      <label className="formFieldLabel" htmlFor="tel">
                        Tally Serial Number
                      </label>
                      <input
                        type="number"
                        id="tally"
                        className="formFieldInput"
                        placeholder="Enter your Tally Serial number"
                        name="tally"
                        value={tally}
                        onChange={registerDataChange}
                      />
                    </div>

                    <div className="formField">
                      <label className="formFieldLabel" htmlFor="password">
                        Password
                      </label>
                      <div className="password-input-container">
                        <input
                          id="password"
                          className="formFieldInput"
                          placeholder="Enter your password"
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={password}
                          onChange={registerDataChange}
                        />

                        <IconButton
                          onClick={toggleShowPassword}
                          edge="end"
                          style={{ marginTop: "5px", color: "#555abf" }}
                        >
                          {!showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </div>
                    </div>

                    <div className="formField">
                      <label className="formFieldLabel" htmlFor="password">
                        Confirm Password
                      </label>
                      <div className="password-input-container">
                        <input
                          id="confirm"
                          className="formFieldInput"
                          placeholder="Re-Enter your password"
                          type={showPassword ? "text" : "password"}
                          name="confirm"
                          value={confirm}
                          onChange={registerDataChange}
                        />

                        <IconButton
                          onClick={toggleShowPassword}
                          edge="end"
                          style={{ marginTop: "5px", color: "#555abf" }}
                        >
                          {!showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </div>
                    </div>

                    <div className="formField">
                      <label className="formFieldCheckboxLabel">
                        <input
                          className="formFieldCheckbox"
                          type="checkbox"
                          name="hasAgreed"
                          checked={hasAgreed}
                          onChange={(e) => setHasAgreed(e.target.checked)}
                        />
                        &nbsp; I agree to all statements in
                        <a href="null" className="formFieldTermsLink">
                          terms &amp; conditions
                        </a>
                      </label>
                    </div>

                    <div className="formFieldButtonParent">
                      <button className="formFieldButton" type="submit">
                        Create
                      </button>
                    </div>
                  </form>

                  {/* OTP Dialog */}
                  <OTPDialog
                    open={otpDialogOpen}
                    handleClose={handleOtpDialogClose}
                    handleVerify={handleOtpVerify}
                    handleResend={sendOTP}
                    loading={load}
                  />
                </div>
              )}
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default LoginSignUp;
