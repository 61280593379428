import React, { Fragment, useEffect } from "react";
import "./notificationpanel.css";
import MetaData from "../layout/MetaData";
import Sidebar from "./Sidebar";
import { DataGrid } from "@mui/x-data-grid";
import { format } from "date-fns";
import { getAllRecentOrders, clearErrors } from "../../actions/orderAction";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import DetailsIcon from "@material-ui/icons/DeveloperBoard";

const NotificationPanel = ({ history }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { error, orders } = useSelector((state) => state.recentOrders);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    dispatch(getAllRecentOrders());
  }, [dispatch, alert]);

  const columns = [
    {
      field: "id",
      headerName: "Order ID",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 150,
      flex: 0.3,
    },
    {
      field: "activeAt",
      sortable: false,
      disableColumnMenu: true,
      headerName: "Activation Date",
      minWidth: 100,
      flex: 0.2,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 150,
      flex: 0.2,
      cellClassName: (params) => {
        return (params.id, "status") === "Activated"
          ? "greenColor"
          : "redColor";
      },
    },

    {
      field: "itemsQty",
      headerName: "Product QTY",
      sortable: false,
      disableColumnMenu: true,
      type: "number",
      minWidth: 150,
      flex: 0.2,
    },
    {
      field: "amount",
      headerName: "Amount",
      sortable: false,
      disableColumnMenu: true,
      type: "number",
      minWidth: 150,
      flex: 0.2,
    },

    {
      field: "actions",
      flex: 0.3,
      headerName: "Actions",
      minWidth: 150,
      type: "number",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Fragment>
            <Link to={`/admin/order/${params.id}`}>
              <DetailsIcon/>
            </Link>
          </Fragment>
        );
      },
    },
  ];

  const rows = [];

  orders &&
    orders.forEach((item) => {
      rows.push({
        id: item._id,
        activeAt: item.activeAt
          ? format(new Date(item.activeAt), "MMMM d, yyyy")
          : "Not Activated",
        itemsQty: item.orderItems.length,
        amount: item.totalPrice,
        status: item.orderStatus,
      });
    });

  return (
    <Fragment>
      <MetaData title={`NOTIFICATIONS - Admin`} />

      <div className="dashboard">
        <Sidebar />
        <div className="productListContainer">
          <h1 id="productListHeading">LATEST ORDERS</h1>

          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            className="productListTable"
            autoHeight
          />
        </div>
      </div>
    </Fragment>
  );
};

export default NotificationPanel;
