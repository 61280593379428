
import React, { createContext, useContext, useEffect,useMemo } from 'react';


import { io } from 'socket.io-client';

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
    
    

    const socket = useMemo(
        () =>
          io("", {
            withCredentials: true,
          }),
        []
      );

  useEffect(() => {

    socket.on("connect", () => {
        console.log('connected to server',socket.id);

      });
    return () => {
      socket.disconnect(); // Disconnect the socket when the component unmounts
    };
  }, [socket]);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(SocketContext);
};
