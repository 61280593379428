import {  useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../../../images/logo-white.png";
import "./navbar.css";
import {
  FaBagShopping,
  FaCircleInfo,
  FaHouse,
  FaPhone,
  FaUser,
} from "react-icons/fa6";
import {  useSelector } from "react-redux";
import Snowfall from "react-snowfall";


const Navbar = () => {
  const { isAuthenticated } = useSelector((state) => state.user);
  const { command } = useSelector((state) => state.admin);
  const navRef = useRef();
  
  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const isWinter = () => {
    const nowDate = new Date();
    const startDate = new Date(nowDate.getFullYear(), 10, 30);
    const endDate = new Date(nowDate.getFullYear(), 0, 31);
    startDate.setFullYear(startDate.getFullYear() - 1);
    return nowDate >= startDate && nowDate < endDate;
  };

  const isWinterSeason = isWinter();
  return (
    <>
      <header>
        {isWinterSeason && <Snowfall snowflakeCount={50} />}

        <nav ref={navRef}>
          <a href="/">
            {" "}
            <FaHouse /> Home
          </a>

          {command &&
            command.isSelling && (
              <a href="/products">
                {" "}
                <FaBagShopping /> Shop
              </a>
            )}

          <a href="/about">
            {" "}
            <FaCircleInfo /> About
          </a>

          <a href="/contact">
            {" "}
            <FaPhone /> Contact
          </a>
          {!isAuthenticated && (
            <a href="/login">
              <FaUser /> Login / Signup
            </a>
          )}

          <img className="logoMobile" src={logo} alt="Logo" />
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </header>
    </>
  );
};

export default Navbar;
