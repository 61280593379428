import React, { useEffect, useMemo } from "react";
import Sidebar from "./Sidebar.js";
import MetaData from "../layout/MetaData.js";
import "./dashboard.css";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Doughnut, Line } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminProduct,
  getIsSelling,
  updateIsSelling,
} from "../../actions/productAction";
import { getAllOrders } from "../../actions/orderAction.js";
import { getAllUsers } from "../../actions/userActions.js";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { io } from "socket.io-client";

const Dashboard = () => {
  const dispatch = useDispatch();

  const { products } = useSelector((state) => state.products);
  const { orders } = useSelector((state) => state.allOrders);
  const { users } = useSelector((state) => state.allUsers);
  const { user } = useSelector((state) => state.user);
  const { command } = useSelector((state) => state.admin);

  const handleStartStop = (e) => {
    e.preventDefault();
    if (command && command.isSelling !== undefined) {
      const updatedIsSelling = !command.isSelling;
      dispatch(updateIsSelling(updatedIsSelling, user.name));
    }
  };

  let outOfStock = 0;

  products &&
    products.forEach((item) => {
      if (item.Stock === 0) {
        outOfStock += 1;
      }
    });
  const socket = useMemo(
    () =>
      io("", {
        withCredentials: true,
      }),
    []
  );
  useEffect(() => {
    dispatch(getAdminProduct());
    dispatch(getAllOrders());
    dispatch(getAllUsers());

    const fetchData = () => {
      dispatch(getAdminProduct())
        .then(() => dispatch(getIsSelling()))
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };
    fetchData();


    socket.on("orderDetails", (data) => {
      console.log(data.orderItems);
      toast.success(`New order Placed! ${data.user}`, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: "Slide",
        // onClick: () => {
        //   history.push(`/admin/order/${productId}`);
        // },
      });
    });
  }, [dispatch, toast, socket]);

  let totalAmount = 0;

  orders &&
    orders.forEach((item) => {
      totalAmount += item.totalPrice;
    });

  const lineState = {
    labels: ["Initial Amount", "Amount Earned"],
    datasets: [
      {
        label: "TOTAL AMOUNT",
        backgroundColor: ["tomato"],
        hoverBackgroundColor: ["rgb(197, 72, 49)"],
        data: [0, totalAmount],
      },
    ],
  };

  const doughnutState = {
    labels: ["Out of Stock", "InStock"],
    datasets: [
      {
        backgroundColor: ["#00A6B4", "#6800B4"],
        hoverBackgroundColor: ["#4B5000", "#35014F"],
        data: [outOfStock, products.length - outOfStock],
      },
    ],
  };

  return (
    <div className="dashboard">
      <MetaData title="Dashboard - Admin Panel" />
      <Sidebar />
      <div className="dashboardContainer">
        <Typography component="h1">Dashboard</Typography>

        <div className="enbleBuyBtn">
          <button
            className={
              command && command.isSelling === true ? "startSelling" : "stopSelling"
            }
            onClick={handleStartStop}
          >
            {command && command.isSelling === true
              ? "Stop Selling"
              : "Start Selling"}
          </button>
        </div>

        <div className="dashboardSummary">
          <div>
            <p>
              Total Amount <br /> ₹{totalAmount}
            </p>
          </div>
          <div className="dashboardSummaryBox2">
            <Link to="/admin/products">
              <p>Product</p>
              <p>{products && products.length}</p>
            </Link>
            <Link to="/admin/orders">
              <p>Orders</p>
              <p>{orders && orders.length}</p>
            </Link>
            <Link to="/admin/users">
              <p>Users</p>
              <p>{users && users.length}</p>
            </Link>
          </div>
        </div>
        <div className="lineChart">
          <Line data={lineState} />
        </div>
        <div className="doughnutChart">
          <Doughnut data={doughnutState} />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Dashboard;
