import "./App.css";
import Navbar from "./component/layout/Header/Navbar.js";
import Footer from "./component/layout/Footer/Footer.js";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React from "react";
import ProductDetails from "./component/Product/ProductDetails.js";
import Products from "./component/Product/Products.js";
import Search from "./component/Product/Search.js";
import LoginSignUp from "./component/User/LoginSignUp.js";
import store from "./Store.js";
import { loadUser } from "./actions/userActions.js";
import UserOptions from "./component/layout/Header/UserOptions.js";
import { useSelector } from "react-redux";
import Profile from "./component/User/userProfile.js";
import UpdateProfile from "./component/User/UpdateProfile.js";
import UpdatePassword from "./component/User/UpdatePassword.js";
import ForgotPassword from "./component/User/ForgotPassword.js";
import ResetPassword from "./component/User/ResetPassword.js";
import Cart from "./component/Cart/Cart.js";
import ConfirmOrder from "./component/Cart/ConfirmOrder.js";
import OrderSuccess from "./component/Cart/OrderSuccess.js";
import CloudNavigator from "./component/Cloud/CloudNavigator.js";
import ProtectedRoute from "./component/Route/ProtectedRoute.js";
import Home from "./component/Home/Home.js";
import MyOrders from "./component/Order/MyOrders.js";
import OrderDetails from "./component/Order/OrderDetails.js";
import AboutUs from "./component/layout/About&ContactUS/AboutUs.js";
import ContactUs from "./component/layout/About&ContactUS/Contact.js";
import Dashboard from "./component/Admin/Dashboard.js";
import ProductList from "./component/Admin/ProductList.js";
import NewProduct from "./component/Admin/NewProduct.js";
import UpdateProduct from "./component/Admin/UpdateProduct.js";
import OrderList from "./component/Admin/OrderList.js";
import processOrder from "./component/Admin/processOrder.js";
import UsersList from "./component/Admin/UsersList.js";
import UpdateUser from "./component/Admin/UpdateUser.js";
import ProductReviews from "./component/Admin/ProductReviews.js";
import NotFound from "./component/layout/NotFound/NotFound.js";
import NotificationPanel from "./component/Admin/NotificationPanel.js";
import Employee from "./component/Team/Employee.js";
import { getIsSelling } from "./actions/productAction.js";


const App = () => {
  const { isAuthenticated, user, loading } = useSelector((state) => state.user);

  React.useEffect(() => {
    store.dispatch(getIsSelling());
    store.dispatch(loadUser());
  }, []);
  window.addEventListener("contextmenu", (e) => e.preventDefault());

  return (
    <Router>
      {isAuthenticated && <UserOptions user={user} />}
      <Navbar />

      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/product/:id">
          <ProductDetails />
        </Route>
        <Route exact path="/products">
          <Products />
        </Route>
        <Route path="/products/:keyword">
          <Products />
        </Route>
        <Route exact path="/search">
          <Search />
        </Route>
        <Route exact path="/login">
          <LoginSignUp />
        </Route>
        <Route exact path="/cloud">
          <CloudNavigator />
        </Route>
        <ProtectedRoute exact path="/order/confirm" component={ConfirmOrder} />
        <ProtectedRoute exact path="/user/profile" component={Profile} />
        <ProtectedRoute
          exact
          path="/user/profile/update"
          component={UpdateProfile}
        />

        <ProtectedRoute
          exact
          path="/user/profile/password/update"
          component={UpdatePassword}
        />
        <ProtectedRoute exact path="/orders" component={MyOrders} />
        <ProtectedRoute exact path="/order/:id" component={OrderDetails} />
        <ProtectedRoute
          isAdmin={true}
          exact
          path="/admin/dashboard"
          component={Dashboard}
        />
        <ProtectedRoute
          isAdmin={true}
          exact
          path="/admin/products"
          component={ProductList}
        />

        <ProtectedRoute
          isAdmin={true}
          exact
          path="/admin/product/new"
          component={NewProduct}
        />

        <ProtectedRoute
          isAdmin={true}
          exact
          path="/admin/product/:id"
          component={UpdateProduct}
        />
        <ProtectedRoute
          isAdmin={true}
          exact
          path="/admin/orders"
          component={OrderList}
        />
        <ProtectedRoute
          isAdmin={true}
          exact
          path="/admin/order/:id"
          component={processOrder}
        />
        <ProtectedRoute
          isAdmin={true}
          exact
          path="/admin/users"
          component={UsersList}
        />
        <ProtectedRoute
          isAdmin={true}
          exact
          path="/admin/user/:id"
          component={UpdateUser}
        />

        <ProtectedRoute
          isAdmin={true}
          exact
          path="/admin/reviews"
          component={ProductReviews}
        />

        <ProtectedRoute
          isAdmin={true}
          exact
          path="/admin/notifications"
          component={NotificationPanel}
        />

        <Route exact path="/user/profile/password/forgot">
          <ForgotPassword />
        </Route>
        <Route exact path="/user/profile/password/reset/:token">
          <ResetPassword />
        </Route>
        <Route exact path="/cart">
          <Cart />
        </Route>
        <Route exact path="/about">
          <AboutUs />
        </Route>
        <Route exact path="/contact">
          <ContactUs />
        </Route>
        <ProtectedRoute
          exact
          path="/payment/success"
          component={OrderSuccess}
        />
        <Route
        exact
        path="/employee"
        component={Employee}
        />
        <Route component={NotFound} />
      </Switch>

      <Footer />
    </Router>
  );
};

export default App;
