import React, { Fragment, useEffect, useState } from "react";
import "./ProductDetails.css";

import { useSelector, useDispatch } from "react-redux";
import {
  clearErrors,
  getIsSelling,
  getProductDetails,
  newReview,
} from "../../actions/productAction";
import { useParams, useHistory } from "react-router-dom";
import MetaData from "../layout/MetaData";
import ReviewCard from "./ReviewCard.js";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import Loader from "../layout/Loader/Loader.js";
import { addItemsToCart } from "../../actions/cartAction";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import { Rating } from "@material-ui/lab";
import { useAlert } from "react-alert";
import { NEW_REVIEW_RESET } from "../../constants/productConstants";

const ProductDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  const params = useParams();

  const productId = useParams().id;

  const { product, loading, error } = useSelector(
    (state) => state.productDetails
  );
  const { success, error: reviewError } = useSelector(
    (state) => state.newReview
  );
  const { command } = useSelector((state) => state.admin);
  const options = {
    value: product.ratings,
    readOnly: true,
    precision: 0.5,
  };

  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const reviewstar = {
    value: rating,
    precision: 0.5,
    size: "medium",
  };

  const buyNowHandler = () => {
    dispatch(addItemsToCart(params.id, 1, 0)).then(() => {
      history.push("/order/confirm");
    });
  };

  const addToCartHandler = () => {
    dispatch(addItemsToCart(params.id, 1, 0));
    alert.success("Item Added To Cart");
  };

  const submitReviewToggle = () => {
    open ? setOpen(false) : setOpen(true);
  };

  const reviewSubmitHandler = () => {
    if (!rating || !comment.trim()) {
      alert.error("Please provide both a rating and a comment.");
      return;
    }

    const wordCount = comment.trim().split(/\s+/).length;
    if (wordCount > 80) {
      alert.error("Comment exceeds the word limit of 150 words.");
      return;
    }

    const myForm = new FormData();

    myForm.set("rating", rating);
    myForm.set("comment", comment);
    myForm.set("productId", params.id);

    dispatch(newReview(myForm));

    setOpen(false);
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (reviewError) {
      alert.error(reviewError);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("Review Submitted Successfully");
      dispatch({ type: NEW_REVIEW_RESET });
    }

    dispatch(getProductDetails(productId));
    dispatch(getIsSelling());
  }, [dispatch, productId, error, alert, reviewError, success]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title={`${product.name} -- TALLYADDONS`} />
          <div className="ProductDetails">
            <div>
              <div>
                <img
                  className="CarouselImage"
                  key={0}
                  src={product.images}
                  alt={product.name}
                />
              </div>
            </div>

            <div>
              <div className="detailsBlock-1">
                <h2>{product.name}</h2>
                <p>Product # {product._id}</p>
              </div>
              <div className="detailsBlock-2">
                <Rating {...options} />
                <span className="detailsBlock-2-span">
                  {" "}
                  {product.numOfReviews} Reviews
                </span>
              </div>
              <div className="detailsBlock-3">
                <div className="detailsBlock-3-price">
                  <h1>{`₹ ${product.price} /`}</h1>
                  <h2>Year</h2>
                  {/* <h4>{` ${Math.round((1 + 0.45) * product.price)}`}</h4>
                  <p>45% off</p> */}
                </div>

                <div
                  className={
                    command && command.isSelling === true
                      ? "detailsBlock-3-1"
                      : "detailsBlock-3-1-disabled"
                  }
                >
                  <button
                    disabled={product.Stock < 1 ? true : false}
                    onClick={buyNowHandler}
                  >
                    Buy Now
                  </button>

                  <button
                    disabled={product.Stock < 1 ? true : false}
                    onClick={addToCartHandler}
                  >
                    Add to Cart
                  </button>
                </div>
                <p>
                  Status:
                  <b className={product.Stock < 1 ? "redColor" : "greenColor"}>
                    {product.Stock < 1 ? "OutOfStock" : "InStock"}
                  </b>
                </p>
              </div>

              <div className="detailsBlock-4">
                Description : <p>{product.description}</p>
              </div>
              <button onClick={submitReviewToggle} className="submitReview">
                Submit Review
              </button>
            </div>
          </div>
          <h3 className="reviewsHeading">REVIEWS</h3>

          <Dialog
            aria-labelledby="simple-dialog-title"
            open={open}
            onClose={submitReviewToggle}
          >
            <DialogTitle>Submit Review</DialogTitle>
            <DialogContent className="submitDialog">
              <Rating
                onChange={(e) => setRating(e.target.value)}
                {...reviewstar}
              />

              <textarea
                className="submitDialogTextArea"
                cols="30"
                rows="5"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </DialogContent>
            <DialogActions>
              <button onClick={submitReviewToggle} className="closeBtn">
                Cancel
              </button>
              <button onClick={reviewSubmitHandler} className="submitBtn">
                Submit
              </button>
            </DialogActions>
          </Dialog>

          {product.reviews && product.reviews[0] ? (
            <div className="allReviews">
              <Carousel
                className="reviews"
                infiniteLoop={true}
                autoPlay={true}
                showStatus={false}
                showArrows={false}
                useKeyboardArrows={true}
                interval={9000}
                
              >
                {product.reviews &&
                  product.reviews.map((review) => (
                    <ReviewCard key={review._id} review={review} />
                  ))}
              </Carousel>
            </div>
          ) : (
            <p className="noReviews">No Reviews Yet</p>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default ProductDetails;
