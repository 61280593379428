import React from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import "./orderSuccess.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearCart } from "../../actions/cartAction";
import { Redirect } from "react-router-dom";

const OrderSuccess = ({ history }) => {
  const dispatch = useDispatch();

  const { order } = useSelector(
    (state) => state.newOrder
  );

  useEffect(() => {
    dispatch(clearCart());
    if (!order || order.success === false) {
      history.push('/');
    }
  }, [dispatch,order,history]);

  const ordersDetails = () => {
    history.push("/orders");
  };

  const navigateHome = () => {
    history.push("/")
  };
  const tcpDownloader= () => {
  
  };

  return (
    <div className="orderSuccess">
      <CheckCircleIcon />

      <h4>
        Your order has been successfully placed!
        <br></br>
        <br></br>
        Download the TCP Manager Application &amp; get your TCP file now!
      </h4>

      <div className="buttonChamber">
        <button onClick={navigateHome}>Home</button>
        <button onClick={ordersDetails}>Order Details</button>
        
      </div>
      <div className="secondButtonChamber">
        <button onClick={tcpDownloader}>Download TCP Manager</button>
      </div>
    </div>
  );
};

export default OrderSuccess;
