import React from "react";
import "./WhyUs.css";

export const WhyUs = () => {
  return (
    <div className="whyUs">
      <h4 className="whyUsCardHeading">Why Choose Us?</h4>
      <div>
        {/* Your grid with 6 div cards in a 3x2 layout */}
        <div className="grid-container">
          <div className="whyUsCardOdd">
            {" "}
            <h4>Customized Solutions</h4>
            <ul>
              <li>
                <p>Tailored IT solutions to meet specific business needs.</p>
              </li>
              <li>
                <p>Scalable services that grow with your organization.</p>
              </li>
            </ul>
          </div>
          <div className="whyUsCardEven">
            {" "}
            <h4>Proven Track Record</h4>
            <ul>
              <li>
                <p>Successful project deliveries and client testimonials.</p>
              </li>
              <li>
                <p>Demonstrated ability to handle complex IT challenges.</p>
              </li>
            </ul>
          </div>
          <div className="whyUsCardOdd">
            {" "}
            <h4>Innovative Problem-Solving</h4>
            <ul>
              <li>
                <p>
                  Creative problem-solving approach to tackle unique IT issues.
                </p>
              </li>
              <li>
                <p>
                  Ability to devise innovative solutions for increased
                  efficiency.
                </p>
              </li>
            </ul>
          </div>
          <div className="whyUsCardEven">
            {" "}
            <h4>Cost-Effective Solutions</h4>
            <ul>
              <li>
                <p>
                  Competitive pricing models without compromising on quality.
                </p>
              </li>
              <li>
                <p>Transparent pricing structures with no hidden costs.</p>
              </li>
            </ul>
          </div>
          <div className="whyUsCardOdd">
            {" "}
            <h4>Collaborative Partnership</h4>
            <ul>
              <li>
                <p>
                  Establishing a collaborative and communicative partnership.
                </p>
              </li>
              <li>
                <p>
                  Working closely with clients to understand their unique needs.
                </p>
              </li>
            </ul>
          </div>
          <div className="whyUsCardEven">
            {" "}
            <h4>Continuous Innovation</h4>
            <ul>
              <li>
                <p>Commitment to ongoing research and development.</p>
              </li>
              <li>
                <p>
                  Integration of the latest innovations to enhance service
                  offerings.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
