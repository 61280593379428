import React, { useState, useEffect } from 'react';
import './ContinuousCount.css'; 

const ContinuousCount = () => {

  const [costomerCount, setCompanyCount] = useState(0);
  useEffect(() => {
  
    const interval2Id = setInterval(() => {
      setCompanyCount((prevCount) => {
        
        const nextCount = prevCount + 30;

        if (nextCount >= 3000) {
          clearInterval(interval2Id); 
          return 3000; 
        }
        return nextCount;
      });
    }, 10);
    
    
    return () => clearInterval(interval2Id);
  }, []);
  
  

  return (
    
    <div className='continuousNumber'> 
      {/* <h4 className='topText' >We Employ {employeeCount}+ Professionals</h4> */}
      <h2 >Trusted By  {costomerCount}+   Customers</h2>
      {/* <h4 className='bottomText' >Celebrating   {experienceCount}+ years of excellence</h4> */}
    </div>
  );
};

export default ContinuousCount;
