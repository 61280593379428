import React, { Fragment, useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import OtpInput from "react-otp-input";
import "./OtpDialog.css";
import Backdrop from "@material-ui/core/Backdrop";
import otpImage from "../../../src/images/otp-verify.png";
import Loader from "../layout/Loader/Loader";

const OtpDialog = ({
  open,
  handleClose,
  handleVerify,
  handleResend,
  loading,
}) => {
  const [otp, setOtp] = useState("");
  const [countdown, setCountdown] = useState(60);
  const [isResendEnabled, setIsResendEnabled] = useState(false);

  useEffect(() => {
    let intervalId;

    if (countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else {
      setIsResendEnabled(true);
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [countdown]);

  const handleResendClick = () => {
    setCountdown(30);
    setIsResendEnabled(false);
    handleResend();
    setOtp("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    handleVerify(otp);
  };

  const handleOtpDialogClose = () => {
    setOtp("");
    handleClose(); // Close the dialog
  };
  const handleOtpChange = (otp) => {
    setOtp(otp);
  };

  return (
    <Fragment>
      <Backdrop open={open} style={{ zIndex: "10" }} />
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <Dialog
            open={open}
            onClose={handleOtpDialogClose}
            className="sectionDialog"
          >
            <div className="dialogHeaderContainer">
              <h2>OTP</h2>
              <img className="dialogImg" src={otpImage} alt="otp" />
              <h4>Verification Code</h4>
              <p>We have sent a verification code to your phone Number.</p>
            </div>
            <form onSubmit={handleSubmit} className="dialogForm">
              <div className="inputDialog">
                <OtpInput
                  value={otp}
                  onChange={handleOtpChange}
                  numInputs={6}
                  inputStyle={{
                    width: "3vmax",
                    height: "4vmax",
                    margin: "0 0.5vmax",
                    fontSize: "1.8vmax",
                    textAlign: "center",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    outline: "none",
                  }}
                  focusStyle={{
                    borderColor: "#0078d7",
                  }}
                  renderInput={(props) => <input {...props} />}
                />
                

              </div>
              <p className="countdown">
                  {isResendEnabled ? (
                    <span onClick={handleResendClick}>Resend OTP</span>
                  ) : (
                    `Resend OTP after ${countdown} s`
                  )}
                </p>
                <div className="buttonSubmitDiv">
                <button
                  className={`OTPresendButton ${
                    !isResendEnabled ? "disabled" : ""
                  }`}
                  disabled={!isResendEnabled}
                  onClick={handleResendClick}
                >
                  Resend
                </button>
                <button type="submit" className="OTPverifyButton">
                  Verify
                </button>
              </div>
            </form>
          </Dialog>
        </Fragment>
      )}
    </Fragment>
  );
};

export default OtpDialog;
