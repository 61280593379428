import React from "react";
import "./Contact.css";
import {
  FaPhone,
  FaSquareWhatsapp,
  FaLocationPin,
  FaEnvelope,
} from "react-icons/fa6";


const Contact = () => {
  const handleInfoEmailClick = () => {
    window.location.href = "mailto:info@tallyaddons.in";
  };
  const handleWhatsappClick = () => {
    window.location.href = "https://wa.link/g1immo";
  };

  return (
    <div className="contact-container">
      <div className="contact-content-container">
        <div class="contact-content-first">
          <h2>Get in Touch</h2>
          <p>Engage with us for innovative solutions.</p>
        </div>
        <div class="contact-content-second">
          <div class="infoCard">
            <image className="socialIcons">
              <FaPhone />
            </image>
            <h6>
              {" "}
              <strong>Phone</strong>
            </h6>
            <p>
              <span>Mon-Sat from 10am to 6pm</span>
              <br></br>
              +91 90935&nbsp;00449
              <br></br>
              +91 90935&nbsp;004550
              <br></br>
            </p>
          </div>
          <div class="infoCard">
            <image className="socialIcons">
              <FaEnvelope />
            </image>
            <h6>
              <strong>Email</strong>
            </h6>
            <p>
              <span>Our friendly team is here to help.</span>
              <br></br>
              <strong onClick={handleInfoEmailClick} className="emailAddress">
              info@tallyaddons.in
              </strong>
            </p>
          </div>
          <div class="infoCard">
            <image className="socialIcons">
              <FaSquareWhatsapp />
            </image>
            <h6>
              {" "}
              <strong>Whatsapp</strong>
            </h6>
            <p>
              <span>Our friendly team is here to help.</span>
              <br></br>
            </p>

            <button className="WhatsappBTN" onClick={handleWhatsappClick}>Send "HI!"</button>
            
          </div>

          <div class="infoCard">
            <image className="socialIcons">
              <FaLocationPin />
            </image>
            <h6>
              <strong>Office</strong>
            </h6>
            <p>
              <span>Come &amp; say hello at our office.</span>
              <br></br>
              <br></br>
              College Para, Near Bagha Jatin Park,
              <br></br>
              Siliguri-734001,West Bengal
              <br></br>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
