import React, { Fragment, useState, useEffect } from "react";
import "./ForgotPassword.css";
import Loader from "../layout/Loader/Loader";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, forgotPassword } from "../../actions/userActions";
import { useAlert } from "react-alert";
import changeprofile from "../../images/reset-password-email.png";
import MetaData from "../layout/MetaData";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const { error, message, loading } = useSelector(
    (state) => state.forgotPassword
  );

  const [email, setEmail] = useState("");

  const forgotPasswordSubmit = (e) => {
    e.preventDefault();
    const myForm = new FormData();
    myForm.set("email", email);
    dispatch(forgotPassword(myForm));
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (message) {
      alert.success(message);
    }
  }, [dispatch, error, alert, message]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Forgot Password" />
          <div className="App">
            <div className="appAside">
              <div className="illustrate">
                <h2>Easy Steps to Update Your Password</h2>
                <img src={changeprofile} alt="illustrate" />
              </div>
            </div>

            <div className="appForm">
            <div className="formCenter">
            <form className="formFields" onSubmit={forgotPasswordSubmit}>
                  <div className="formField">
                    <label className="formFieldLabel" htmlFor="email">
                      Your Registered Email Address
                    </label>

                    <div className="email-input-container">
                    
                      <input
                        type="email"
                        id="email"
                        className="formFieldInput"
                        placeholder="Enter Email Address"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                   
                  </div>
                  <div className="formFieldButtonParent">
                    <button className="formFieldButton" type="submit">
                     Send Link 
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ForgotPassword;
