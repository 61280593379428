import React, { Fragment, useEffect } from "react";
import "./Home.css";
import Logo from "../../images/logo-white.png";
import ProductCard from "./ProductCard.js";
import MetaData from "../layout/MetaData.js";
import {
  clearErrors,
  getProducts,

} from "../../actions/productAction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../layout/Loader/Loader.js";
import { useAlert } from "react-alert";
import { Link } from "react-router-dom";
import ContinuousCount from "./ContinuousCount.js";
import Snowfall from "react-snowfall";

import OurServices from "./OurServices.js";
import { ContactUs } from "./ContactUs.js";
import { WhyUs } from "./WhyUs.js";
import ServerUnderMaintanance from "./ServerUnderMaintanance.js";

const Home = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, error, products } = useSelector((state) => state.products);
  const { command } = useSelector((state) => state.admin);
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    
      dispatch(getProducts())
        
  }, [dispatch, error, alert]);

  const isWinter = () => {
    const nowDate = new Date();
    const startDate = new Date(nowDate.getFullYear(), 10, 30);
    const endDate = new Date(nowDate.getFullYear(), 0, 31);
    startDate.setFullYear(startDate.getFullYear() - 1);
    return nowDate >= startDate && nowDate < endDate;
  };

  const isWinterSeason = isWinter();

  return (
    <>
      <Fragment>
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            <MetaData title="Tally Addons -- Home" />

            <div className="backgroundHome">
              {isWinterSeason && <Snowfall />}
              <div className="span" />
              <div className="span-hd-cae" />
              <div className="div">
                <div className="span-hd-ffaca" />
                <div className="span-hd-cec" />
              </div>

              <div className="span-hd-ec" />
              <div className="span-hd-daade" />
              <div className="span-hd-bf" />
            </div>

            <div className="homeIconTextContainer">
              <img src={Logo} alt="TallyAddons" />

              <h4>
                Customized your Tally to fit your unique needs, <br></br>
                providing a seamless integration that enhances efficiency &
                productivity.
              </h4>
              <div>
                {command && !command.isSelling ? null : (
                  <Link to="/products">Shop now</Link>
                )}
                <Link to="/cloud">Sign in to the Cloud</Link>
              </div>
              <h5>Celebrating 10 years of excellence! 🌟</h5>
            </div>

            <div className="customerCount">
              <ContinuousCount />
            </div>

            <OurServices />
            <WhyUs />

            {/* {command && command.isSelling !== true ? (
              <ServerUnderMaintanance />
            ) : (
              <div className="mainContainer">
                <h2 className="homeHeading">Featured Products</h2>
                <div className="container" id="container">
                  {products &&
                    products.map((product) => (
                      <ProductCard product={product} />
                    ))}
                </div>
              </div>
            )} */}

            <ContactUs />
          </Fragment>
        )}
      </Fragment>
    </>
  );
};
export default Home;
