import React, { Fragment, useState, useEffect } from "react";
import Loader from "../layout/Loader/Loader";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  updateProfile,
  loadUser,
} from "../../actions/userActions.js";
import { useAlert } from "react-alert";
import { UPDATE_PROFILE_RESET } from "../../constants/userConstants";
import MetaData from "../layout/MetaData";
import { useHistory } from "react-router-dom";
import changeprofile from "../../images/profile-data.png";
import OtpDialog from "./OtpDialog.js";

const UpdateProfile = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  const { user } = useSelector((state) => state.user);
  const { error, isUpdated, loading } = useSelector((state) => state.profile);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [otpDialogOpen, setOtpDialogOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const handleOtpDialogClose = async () => {
    setOtpDialogOpen(false);
  };

  const handleOtpVerify = async (otp) => {
    try {
      setLoad(true);
      const config = { headers: { "Content-Type": "application/json" } };
      // Make an HTTP request to your backend to verify the OTP
      const response = await axios.post(
        `/api/v1/verifyotp`,
        { phone, otp },
        config
      );
      if (response.data.success === true) {
        alert.success("OTP verified successfully");

        setOtpDialogOpen(false);
        dispatch(updateProfile(name, email, phone, company));
      } else {
        alert.error(response.data.message);
      }
    } catch (error) {
      alert.error("Failed to verify OTP:", error.message);
    } finally {
      setLoad(false); // Set loading state to false regardless of success or failure
    }
  };

  const sendOTP = async () => {
    try {
      setLoad(true);
      const config = { headers: { "Content-Type": "application/json" } };
      // Make an HTTP request to your backend to verify the OTP
      const response = await axios.post(`/api/v1/sendotp`, { phone }, config);

      if (response.data.message === "pending") {
        alert.success("Check your Phone");
      } else {
        alert.error(response.data.message);
      }
    } catch (error) {
      alert.error(error.message);
    } finally {
      setLoad(false);
    }
  };

  const updateProfileSubmit = (e) => {
    e.preventDefault();
    const myForm = new FormData();
    myForm.set("name", name);
    myForm.set("phone", phone);
    myForm.set("email", email);
    myForm.set("company", company);
    setOtpDialogOpen(true);
    sendOTP();
  };

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setPhone(user.phone);
      setCompany(user.company);
    }

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (isUpdated) {
      alert.success("Profile Updated Successfully");
      dispatch(loadUser());

      history.push("/user/profile");

      dispatch({
        type: UPDATE_PROFILE_RESET,
      });
    }
  }, [dispatch, error, alert, history, user, isUpdated]);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Update Profile" />
          <div className="App">
            <div className="appAside">
              <div className="illustrate">
                <h2>Easy Steps to Update Your Profile</h2>
                <img src={changeprofile} alt="illustrate" />
              </div>
            </div>

            <div className="appForm">
              <div className="formCenter">
                <form className="formFields" onSubmit={updateProfileSubmit}>
                  <div className="formField">
                    <label className="formFieldLabel" htmlFor="name">
                      Full Name
                    </label>

                    <div className="password-input-container">
                      <input
                        type="name"
                        id="name"
                        className="formFieldInput"
                        placeholder="Enter your name"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <label className="formFieldLabel" htmlFor="password">
                      New Email Address
                    </label>
                    <div className="password-input-container">
                      <input
                        type="email"
                        id="email"
                        className="formFieldInput"
                        placeholder="Enter your new email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <label className="formFieldLabel" htmlFor="password">
                      NEW CONTACT NUMBER
                    </label>
                    <div className="password-input-container">
                      <input
                        type="tel"
                        id="phone"
                        className="formFieldInput"
                        placeholder="Enter your new contact number"
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                    <label className="formFieldLabel" htmlFor="password">
                      NEW COMPANY NAME
                    </label>
                    <div className="password-input-container">
                      <input
                        type="text"
                        id="company"
                        className="formFieldInput"
                        placeholder="Enter your new contact number"
                        name="company"
                        value={company}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="formFieldButtonParent">
                    <button className="formFieldButton" type="submit">
                      Update Profile
                    </button>
                  </div>
                </form>

                {/* OTP Dialog */}
                <OtpDialog
                  open={otpDialogOpen}
                  handleClose={handleOtpDialogClose}
                  handleVerify={handleOtpVerify}
                  handleResend={sendOTP}
                  loading={load}
                />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default UpdateProfile;
