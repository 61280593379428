import React from "react";
import "./employee.css";
import devImg from "../../images/dev.jpg";
import defaultImg from "../../images/default.jpg";
import sabirulImg from "../../images/sabirul.jpg";
import {
  FaSquareInstagram,
  FaSquareWhatsapp,
  FaLinkedin,
} from "react-icons/fa6";

const Employee = () => {
  return (
    <div className="employeeContainer">
      <div className="employeeContainer-content-container">
        <div class="employeeContainer-content-first">
          <h2>Meet Our Professional Team!</h2>
        </div>
        <div class="employeeContainer-content-second">
          <div class="employeeContainerInfoCard">
            <img src={sabirulImg} alt="dev"></img>
            <h6>
              <strong>Sabirul Khan</strong>
            </h6>
            <p>
              <span>(Chief HR Officer)</span>
            </p>
            <div className="employeeSocialLinks">
              <a
                className="employeeIcons"
                href="https://twitter.com/your_twitter_profile"
              >
                <FaSquareInstagram />
              </a>

              <a
                className="employeeIcons"
                href="https://github.com/your_github_profile"
              >
                <FaSquareWhatsapp />
              </a>

              <a
                className="employeeIcons"
                href="https://linkedin.com/in/your_linkedin_profile"
              >
                <FaLinkedin />
              </a>
            </div>
          </div>
          <div class="employeeContainerInfoCard">
            <img src={devImg} alt="dev"></img>
            <h6>
              <strong>Jyotirmoy Dutta</strong>
            </h6>
            <p>
              <span>(Web Developer)</span>
            </p>
            <div className="employeeSocialLinks">
              <a
                className="employeeIcons"
                href="https://twitter.com/your_twitter_profile"
              >
                <FaSquareInstagram />
              </a>

              <a
                className="employeeIcons"
                href="https://github.com/your_github_profile"
              >
                <FaSquareWhatsapp />
              </a>

              <a
                className="employeeIcons"
                href="https://linkedin.com/in/your_linkedin_profile"
              >
                <FaLinkedin />
              </a>
            </div>
          </div>
          <div class="employeeContainerInfoCard">
            <img src={devImg} alt="dev"></img>
            <h6>
              <strong>Jyotirmoy Dutta</strong>
            </h6>
            <p>
              <span>(Web Developer)</span>
            </p>
            <div className="employeeSocialLinks">
              <a
                className="employeeIcons"
                href="https://twitter.com/your_twitter_profile"
              >
                <FaSquareInstagram />
              </a>

              <a
                className="employeeIcons"
                href="https://github.com/your_github_profile"
              >
                <FaSquareWhatsapp />
              </a>

              <a
                className="employeeIcons"
                href="https://linkedin.com/in/your_linkedin_profile"
              >
                <FaLinkedin />
              </a>
            </div>
          </div>
          <div class="employeeContainerInfoCard">
            <img src={devImg} alt="dev"></img>
            <h6>
              <strong>Jyotirmoy Dutta</strong>
            </h6>
            <p>
              <span>(Web Developer)</span>
            </p>
            <div className="employeeSocialLinks">
              <a
                className="employeeIcons"
                href="https://twitter.com/your_twitter_profile"
              >
                <FaSquareInstagram />
              </a>

              <a
                className="employeeIcons"
                href="https://github.com/your_github_profile"
              >
                <FaSquareWhatsapp />
              </a>

              <a
                className="employeeIcons"
                href="https://linkedin.com/in/your_linkedin_profile"
              >
                <FaLinkedin />
              </a>
            </div>
          </div>
          <div class="employeeContainerInfoCard">
            <img src={devImg} alt="dev"></img>
            <h6>
              <strong>Jyotirmoy Dutta</strong>
            </h6>
            <p>
              <span>(Web Developer)</span>
            </p>
            <div className="employeeSocialLinks">
              <a
                className="employeeIcons"
                href="https://twitter.com/your_twitter_profile"
              >
                <FaSquareInstagram />
              </a>

              <a
                className="employeeIcons"
                href="https://github.com/your_github_profile"
              >
                <FaSquareWhatsapp />
              </a>

              <a
                className="employeeIcons"
                href="https://linkedin.com/in/your_linkedin_profile"
              >
                <FaLinkedin />
              </a>
            </div>
          </div>
          <div class="employeeContainerInfoCard">
            <img src={devImg} alt="dev"></img>
            <h6>
              <strong>Jyotirmoy Dutta</strong>
            </h6>
            <p>
              <span>(Web Developer)</span>
            </p>
            <div className="employeeSocialLinks">
              <a
                className="employeeIcons"
                href="https://twitter.com/your_twitter_profile"
              >
                <FaSquareInstagram />
              </a>

              <a
                className="employeeIcons"
                href="https://github.com/your_github_profile"
              >
                <FaSquareWhatsapp />
              </a>

              <a
                className="employeeIcons"
                href="https://linkedin.com/in/your_linkedin_profile"
              >
                <FaLinkedin />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Employee;
