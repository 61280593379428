import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import MetaData from "../layout/MetaData";
import Loader from "../layout/Loader/Loader";
import { Link, useHistory } from "react-router-dom";

import "./userProfile.css";
import Snowfall from "react-snowfall";

const UserProfile = () => {
  const { user, loading, isAuthenticated } = useSelector((state) => state.user);
  const history = useHistory();
  const isWinter = () => {
    const nowDate = new Date();
    const startDate = new Date(nowDate.getFullYear(), 10, 30); 
    const endDate = new Date(nowDate.getFullYear(), 0, 31); 
    startDate.setFullYear(startDate.getFullYear() - 1);
    return nowDate >= startDate && nowDate < endDate;
  };

  const isWinterSeason = isWinter();

  useEffect(() => {
    if (isAuthenticated === false) {
      history.push("/login");
    }

    const unlisten = history.listen((action) => {
      if (action === "POP") {
        history.replace("/");
      }
    });

    return () => {
      unlisten();
    };
  }, [history, isAuthenticated]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title={`${user.name}'s Profile`} />
          <div className="background">
           {isWinterSeason && <Snowfall/>}
            <div className="span" />
            <div className="span-hd-cae" />
            <div className="div">
              <div className="span-hd-ffaca" />
              <div className="span-hd-cec" />
            </div>
            <div className="span-hd-ec" />
            <div className="span-hd-daade" />
            <div className="span-hd-bf" />
          </div>
          <div className="userCardholder ">
            <div className="userCard ">
              <img className="profileAvatar" src={"/Profile.png"} alt={""} />
              <h2 className="username">{user.name}</h2>

              <div className="userDetailsContainer">
                <div className="leftDiv">
                  <h4>Company Name</h4>
                  <h4>Email Address</h4>
                  <h4>Contact Number</h4>
                  <h4>Tally Serial Number</h4>
                  <h4>Joined On</h4>
                </div>

                <div className="midDiv"></div>

                <div className="rightDiv">
                  <h4>{user.company}</h4>
                  <h4>{user.email}</h4>
                  <h4>{user.phone}</h4>
                  <h4>{user.tally}</h4>
                  <h4>{String(user.createdAt).substr(0, 10)}</h4>
                </div>
              </div>
              <div className="bottomDiv">
                <Link to="/user/profile/update">Edit Profile Details</Link>
                <Link to="/user/profile/password/update">Reset Password</Link>
              </div>
              <div className="bottomDivSecond">
                <Link to="/orders">My Orders</Link>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default UserProfile;
