import React from "react";
import { Link } from "react-router-dom";
import { Rating } from "@material-ui/lab";

const ProductCard = ({ product }) => {
  const options = {
    readOnly: true,
    value: product.ratings,
    isHalf: true,
    size:"small"
  };
  return (
    <Link className="productCard" to={`/product/${product._id}`}>
      <img src={product.images} alt={product.name} />
      <p>{product.name}</p>
      <div>
        <Rating {...options} />{" "}
        <span className="productCardSpan">
          ({product.numOfReviews} Reviews)
        </span>
      </div>
      <span>{`₹${product.price}`}</span>
    </Link>
  );
};
export default ProductCard;
