import React, { useEffect, useState } from "react";
import "./ContactUs.css";
import { useDispatch, useSelector } from "react-redux";
import { newSuggestion,clearErrors } from "../../actions/userActions";
import { NEW_SUGGESTION_RESET } from "../../constants/userConstants";
import { useAlert } from "react-alert";


export const ContactUs = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { success, error } = useSelector((state) => state.newSuggestion);

  const [name, setName] = useState("");
  const [company, setcompany] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("company", company);
    myForm.set("email", email);
    myForm.set("message", message);

    dispatch(newSuggestion(myForm));
    e.preventDefault();
   
  };


  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("Review Submitted Successfully");
      dispatch({ type: NEW_SUGGESTION_RESET });
    }
  }, [dispatch,alert,error,success])
  
  return (
    <div className="contactUsFormDiv">
      <div className="leftDiv">
        <div>
          <h2 className="title">Get in Touch</h2>
          <p className="subTitle">
            Your feedback, questions, and suggestions are invaluable to us.
            Kindly utilize the contact form to connect with us. We highly value
            your interest and assure you that we will promptly address your
            inquiry. Thank you for choosing to engage with us.
          </p>
        </div>
      </div>

      <div className="rightDiv">
        <div className="formDiv">
          <form onSubmit={handleSubmit} className="formRow">
            <label htmlFor="inputName">Name:</label>
            <input
              type="text"
              className="getInfo"
              id="inputName"
              name="inputName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />

            <label htmlFor="inputcompany">Company Name:</label>
            <input
              type="text"
              className="getInfo"
              id="inputcompany"
              name="inputcompany"
              value={company}
              onChange={(e) => setcompany(e.target.value)}
              required
            />

            <label htmlFor="inputEmail">Email:</label>
            <input
              type="email"
              id="inputEmail"
              name="inputEmail"
              className="getInfo"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <label htmlFor="inputMessage">Message:</label>
            <textarea
              id="inputMessage"
              name="inputMessage"
              className="getInfoText"
              rows="4"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />

            <button className="btnForm" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
