import React, { Fragment, useState, useEffect } from "react";
import "./UpdatePassword.css";
import Loader from "../layout/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, updatePassword } from "../../actions/userActions";
import { useAlert } from "react-alert";
import { UPDATE_PASSWORD_RESET } from "../../constants/userConstants";
import MetaData from "../layout/MetaData";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import changePassword from "../../images/reset-password.png";
const UpdatePassword = ({ history }) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const { error, isUpdated, loading } = useSelector((state) => state.profile);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const updatePasswordSubmit = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("oldPassword", oldPassword);
    myForm.set("newPassword", newPassword);
    myForm.set("confirmPassword", confirmPassword);

    dispatch(updatePassword(myForm));
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (isUpdated) {
      alert.success("Profile Updated Successfully");

      history.push("/user/profile");

      dispatch({
        type: UPDATE_PASSWORD_RESET,
      });
    }
  }, [dispatch, error, alert, history, isUpdated]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Change Password" />
          <div className="App">
            <div className="appAside">
              <div className="illustrate">
                <h2>Easy Steps to Update Your Password</h2>
                <img src={changePassword} alt="illustrate" />
              </div>
            </div>

            <div className="appForm">
            <div className="formCenter">
            <form className="formFields" onSubmit={updatePasswordSubmit}>
                  <div className="formField">
                    <label className="formFieldLabel" htmlFor="password">
                      Old Password
                    </label>

                    <div className="password-input-container">
                    
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        className="formFieldInput"
                        placeholder="Enter your old password"
                        name="password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                      />

                      <IconButton
                        onClick={toggleShowPassword}
                        edge="end"
                        style={{ marginTop: "5px", color: "#555abf" }}
                      >
                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </div>
                    <label className="formFieldLabel" htmlFor="password">
                      New Password
                    </label>
                    <div className="password-input-container">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="oldPassword"
                        className="formFieldInput"
                        placeholder="Enter your new password"
                        name="oldPassword"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />

                      <IconButton
                        onClick={toggleShowPassword}
                        edge="end"
                        style={{ marginTop: "5px", color: "#555abf" }}
                      >
                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </div>
                    <label className="formFieldLabel" htmlFor="password">
                      Confirm New Password
                    </label>
                    <div className="password-input-container">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="confirmPassword"
                        className="formFieldInput"
                        placeholder="Confirm your new password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />

                      <IconButton
                        onClick={toggleShowPassword}
                        edge="end"
                        style={{ marginTop: "5px", color: "#555abf" }}
                      >
                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </div>
                  </div>
                  <div className="formFieldButtonParent">
                    <button className="formFieldButton" type="submit">
                      Change  Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default UpdatePassword;
