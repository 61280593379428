import {
  PROCESS_PAYMENT_FAIL,
  PROCESS_PAYMENT_REQUEST,
  PROCESS_PAYMENT_SUCCESS,
  VALIDATE_PAYMENT_FAIL,
  VALIDATE_PAYMENT_REQUEST,
  VALIDATE_PAYMENT_SUCCESS,
} from "../constants/paymentConstants";

export const paymentReducer = (state = {}, action) => {
  switch (action.type) {
    case PROCESS_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
        payment: null,
        paymentError: null,
      };

    case PROCESS_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        payment: action.payload,
        paymentError: null,
      };

    case PROCESS_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        payment: null,
        paymentError: action.payload,
      };

    case VALIDATE_PAYMENT_REQUEST:
      return {
        ...state,
        validatingPayment: true,
        validationSuccess: null,
        validationError: null,
      };

    case VALIDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        validatingPayment: false,
        validationSuccess: action.payload,
        validationError: null,
      };

    case VALIDATE_PAYMENT_FAIL:
      return {
        ...state,
        validatingPayment: false,
        validationSuccess: null,
        validationError: action.payload,
      };

    default:
      return state;
  }
};
