import React, { Fragment, useState, useEffect } from "react";
import "./ResetPassword.css";
import Loader from "../layout/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, resetPassword } from "../../actions/userActions";
import { useAlert } from "react-alert";
import MetaData from "../layout/MetaData";

import changePassword from "../../images/reset-password-email.png";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const params = useParams();
  const history = useHistory();

  const { error, success, loading } = useSelector(
    (state) => state.forgotPassword
  );

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const resetPasswordSubmit = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("password", password);
    myForm.set("confirmPassword", confirmPassword);

    dispatch(resetPassword(params.token, myForm));
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("Password Updated Successfully");

      history.push("/login");
    }
  }, [dispatch, error, alert, history, success]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Reset Password" />
          <div className="App">
            <div className="appAside">
              <div className="illustrate">
                <h2>Easy Steps to Update Your Password</h2>
                <img src={changePassword} alt="illustrate" />
              </div>
            </div>

            <div className="appForm">
            <div className="formCenter">
            <form className="formFields" onSubmit={resetPasswordSubmit}>
                  <div className="formField">
                    <label className="formFieldLabel" htmlFor="password">
                      New Password
                    </label>
                    <div className="password-input-container">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="oldPassword"
                        className="formFieldInput"
                        placeholder="Enter your new password"
                        name="oldPassword"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />

                      <IconButton
                        onClick={toggleShowPassword}
                        edge="end"
                        style={{ marginTop: "5px", color: "#555abf" }}
                      >
                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </div>
                    <label className="formFieldLabel" htmlFor="password">
                      Confirm New Password
                    </label>
                    <div className="password-input-container">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="confirmPassword"
                        className="formFieldInput"
                        placeholder="Confirm your new password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />

                      <IconButton
                        onClick={toggleShowPassword}
                        edge="end"
                        style={{ marginTop: "5px", color: "#555abf" }}
                      >
                        {!showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </div>
                  </div>
                  <div className="formFieldButtonParent">
                    <button className="formFieldButton" type="submit">
                      Change  Password
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ResetPassword;
