import React, { Fragment, useEffect } from "react";
import "./orderDetails.css";
import { useSelector, useDispatch } from "react-redux";
import MetaData from "../layout/MetaData";
import { useParams } from "react-router-dom";
import axios from "axios";
import { getOrderDetails, clearErrors } from "../../actions/orderAction";
import Loader from "../layout/Loader/Loader";
import { useAlert } from "react-alert";
import { format } from "date-fns";
import Snowfall from "react-snowfall";

const OrderDetails = () => {
  const params = useParams();

  const { order, error, loading } = useSelector((state) => state.orderDetails);
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const alert = useAlert();

  const isWinter = () => {
    const nowDate = new Date();
    const startDate = new Date(nowDate.getFullYear(), 10, 30);
    const endDate = new Date(nowDate.getFullYear(), 0, 31);
    startDate.setFullYear(startDate.getFullYear() - 1);
    return nowDate >= startDate && nowDate < endDate;
  };

  const isWinterSeason = isWinter();

  const invoicedata = {
    userName: user && user.name,
    userEmail: user && user.email,
    orderId: (order && order._id) || "",
    paymentId: (order && order.paymentInfo && order.paymentInfo.id) || "",
    companyName: (order && order.user && order.user.company) || "",
    tally: (order && order.user && order.user.tally) || "",
    customerName: (order && order.user && order.user.name) || "",
    customerPhone: (order && order.user && order.user.phone) || "",
    paymentStatus:
      (order && order.paymentInfo && order.paymentInfo.status) || "",
    paidAt:
      order && order.paidAt
        ? format(new Date(order.paidAt), "MMMM d, yyyy")
        : "Not available",

    orderItems:
      (order &&
        order.orderItems &&
        order.orderItems.map((item) => ({
          price: item.price || "",
          quantity: item.quantity || "",
          productName: item.name || "",
          subtotal: order.itemsPrice || "",
        }))) ||
      [],

    itemsPrice: (order && order.itemsPrice) || "",
    total: (order && order.subtotal) || (order && order.itemsPrice),
    taxPrice: (order && order.taxPrice) || "",
    totalPrice: (order && order.totalPrice) || "",
  };

  const downloadInvoice = (e) => {
    e.preventDefault();

    axios
      .post("/api/v1/generate-invoice", invoicedata)
      .then((response) => {
        console.log(response.data);
        
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    dispatch(getOrderDetails(params.id));
  }, [dispatch, alert, error, params.id]);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Order Details" />

          <div className="background">
            {isWinterSeason && <Snowfall />}
            <div className="span"></div>
            <div className="span-hd-cae"></div>
            <div className="span-hd-ffaca"></div>
            <div className="span-hd-cec"></div>
            <div className="span-hd-ec"></div>
            <div className="span-hd-daade"></div>
            <div className="span-hd-bf"></div>
          </div>

          <div className="orderDetailsPageOrder">
            <div className="userCardholderOrder">
              <div className="userCardOrder">
                <div className="orderProductDetails">
                  <h4>Order Details</h4>
                  <div className="itemsDetailsContains">
                    {order.orderItems &&
                      order.orderItems.map((item) => (
                        <div className="itemsDetailsCard" key={item._id}>
                          <h6>{item.name}</h6>
                          <h6>
                            Paid: <span>₹{item.price * item.quantity}/-</span>
                          </h6>
                          <h6>
                            Expired in:{" "}
                            <span>
                              {item.expirationDate
                                ? format(
                                    new Date(item.expirationDate),
                                    "MMMM d, yyyy"
                                  )
                                : "Not Activated"}
                            </span>
                          </h6>
                        </div>
                      ))}
                  </div>
                  <div className="commonDetails">
                    <div class="left-content">
                      <h6>Order ID</h6>
                      <h6>Payment ID</h6>
                      <h6>Company Name</h6>
                      <h6>Tally Serial Number</h6>
                      <h6>Account Holder</h6>
                      <h6>Contact Number</h6>
                      <h6>Payment Status</h6>
                      <h6>Payment Date</h6>
                      <h6>Activation Date</h6>
                      <h6>GST</h6>
                      <h6>Total Amount</h6>
                    </div>
                    <div class="right-content">
                      <h6>
                        <span>{order._id}</span>
                      </h6>
                      <h6>
                        <span>{order.paymentInfo && order.paymentInfo.id}</span>
                      </h6>
                      <h6>
                        <span>{order.user && order.user.company}</span>
                      </h6>
                      <h6>
                        <span>{order.user && order.user.tally}</span>
                      </h6>
                      <h6>
                        <span>{order.user && order.user.name}</span>
                      </h6>
                      <h6>
                        <span>{order.user && order.user.phone}</span>
                      </h6>
                      <h6>
                        <span>
                          {order.paymentInfo && order.paymentInfo.status}
                        </span>
                      </h6>
                      <h6>
                        <span>
                          {order && order.paidAt
                            ? format(
                                new Date(order.paidAt),
                                "MMMM d, yyyy HH:mm:ss"
                              )
                            : "Not Available"}
                        </span>
                      </h6>
                      <h6>
                        <span>
                          {order && order.activeAt
                            ? format(new Date(order.activeAt), "MMMM d, yyyy")
                            : "Not Activated"}
                        </span>
                      </h6>
                      <h6>
                        <span>{order && order.taxPrice}</span>
                      </h6>
                      <h6>
                        <span>{order && order.totalPrice}</span>
                      </h6>
                    </div>
                  </div>

                  <button onClick={downloadInvoice}>Download Invoice</button>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default OrderDetails;
