import React, { Fragment, useEffect } from "react";
//import { DataGrid } from "@material-ui/data-grid";
import { DataGrid } from '@mui/x-data-grid';
import "./myOrders.css";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, myOrders } from "../../actions/orderAction";
import Loader from "../layout/Loader/Loader";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";

import MetaData from "../layout/MetaData";
import LaunchIcon from "@material-ui/icons/Launch";

const MyOrders = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const { loading, error, orders } = useSelector((state) => state.myOrders);
  

  const alert = useAlert();

  const columns = [
    { field: "id", headerName: "Order ID",  flex: 1 ,sortable: false,
    disableColumnMenu: true,},

    {
      field: "status",
      headerName: "Status",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.5,
      cellClassName: (params) =>
      params.value === "Delivered" ? "greenColor" : "redColor",
      
    },
    
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      sortable: false,
      disableColumnMenu: true,
      flex: 0.5,
    },

    {
      field: "actions",
      flex: 0.5,
    
      headerName: "Actions",
      type: "number",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Link to={`/order/${params.id}`}>
          <LaunchIcon />
        </Link>
      ),
      
    },
  ];
  const rows = [];

  orders &&
    orders.forEach((item, index) => {
      rows.push({
        
        id: item._id,
        status: item.orderStatus,
        amount: item.totalPrice,
      });
    });

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    dispatch(myOrders());
  }, [dispatch, alert, error]);

  return (
    <Fragment>
      <MetaData title={`${user.name} - Orders`} />
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="myOrdersPage">
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              className="myOrdersTable"
              autoHeight
            />
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default MyOrders;
