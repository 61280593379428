import React from "react";
import "./OurService.css"
import cloudImage from "../../images/Cloud hosting.png";
import customizationImage from "../../images/Customization.png";
import supportImage from "../../images/Customer Support.png";


const OurServices = () => {
  return (
    <div className="ourServices">
  
      <h4>We deliver a comprehensive range of services.</h4>
      <div className="ourServicesContainer">
    
        <div className="card">
      
          <div>
          
            <h6>Tally Customization</h6>
            <p>
              Embrace the future of accounting with Tally on Cloud, offering
              unmatched flexibility, security, and accessibility. Revolutionize
              your business by empowering collaboration, gaining real-time
              insights, and ensuring effortless scalability. Streamline
              operations, boost productivity, and enter a new era of corporate
              agility. Tally on Cloud transcends traditional accounting,
              providing the freedom to excel and thrive in a dynamic digital
              ecosystem.
            </p>

            <img className="illustrations" src={customizationImage} />
          </div>
        </div>

        {/* Card 2 */}
        <div className="card-center">
       
          <div>
         
            <h6>Tally on Cloud</h6>

            <p>
              Elevate your accounting experience with Tally on Cloud—a seamless
              fusion of flexibility, security, and accessibility. Revolutionize
              your business landscape by unlocking collaboration, real-time
              insights, and effortless scalability. Streamline operations, boost
              productivity, and embrace a new era of agility. Tally on Cloud
              transcends traditional accounting constraints, offering a world
              where efficiency meets innovation. Experience the freedom to
              excel, connect, and thrive in the dynamic digital realm.
            </p>

            <img className="illustrations" src={cloudImage} />
          </div>
        </div>

        {/* Card 3 */}
        <div className="card">
          <div>
            <h6>Tally Sales/Supports</h6>
        
            <p>
              Transform your business with Tally Sales/Supports! Streamline
              operations, elevate efficiency, and simplify complexities for
              seamless success. Our dedicated support ensures a smooth journey,
              seamlessly integrating Tally for unparalleled productivity. Choose
              us for a transformative journey toward operational excellence,
              where innovation meets reliability, propelling your venture to
              thrive.
            </p>

            <img className="illustrations" src={supportImage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
