import axios from "axios";
import {
  PROCESS_PAYMENT_FAIL,
  PROCESS_PAYMENT_REQUEST,
  PROCESS_PAYMENT_SUCCESS,
  VALIDATE_PAYMENT_REQUEST,
  VALIDATE_PAYMENT_SUCCESS,
  VALIDATE_PAYMENT_FAIL,
} from "../constants/paymentConstants";

export const processPayment = (paymentData) => async (dispatch) => {
  try {
    dispatch({ type: PROCESS_PAYMENT_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Make an HTTP POST request to the payment processing endpoint
    const { data } = await axios.post(
      "/api/v1/payment/process",
      paymentData,
      config
    );

    dispatch({ type: PROCESS_PAYMENT_SUCCESS, payload: data.success });
   
    return {payment:data.order.id};

  } catch (error) {
    dispatch({
      type: PROCESS_PAYMENT_FAIL,
      payload: error.response.data.message,
    });
    return { payment:{} };
  }
};

// Payment validation action creator
export const validatePayment = (validationData) => async (dispatch) => {
  try {
    dispatch({ type: VALIDATE_PAYMENT_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // Make an HTTP POST request to the payment validation endpoint
    const { data } = await axios.post(
      "/api/v1/payment/validate",
      validationData,
      config
    );
   

    dispatch({ type: VALIDATE_PAYMENT_SUCCESS, payload: data.success });
    return {validator:data.success};
  } catch (error) {
    dispatch({
      type: VALIDATE_PAYMENT_FAIL,
      payload: error.response.data.message,
    });
    return {validator:{}};
  }
};
