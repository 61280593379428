import React, { Fragment, useState } from "react";
import "./CloudNavigator.css";
import Loader from "../layout/Loader/Loader";
import MetaData from "../layout/MetaData";


const CloudNavigator = () => {
    const [loading, setIframeLoaded] = useState(false);
    const loadUrl = "https://vps7.tallyaddons.in";

    const handleIframeLoad = () => {
        setIframeLoaded(false);
      };
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
            <MetaData title="Tally Addons -- Cloud" />
          <div className="cloudBackground">
          
            <iframe
              title="CloudNavigator"
              src={loadUrl}
              onLoad={handleIframeLoad}
            />
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default CloudNavigator;
