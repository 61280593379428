import React, { Fragment, useEffect, useState } from "react";
import "./Products.css";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, getProducts } from "../../actions/productAction";
import Loader from "../layout/Loader/Loader";
import ProductCard from "../Home/ProductCard";
import { useParams } from "react-router-dom";
import Pagination from "react-js-pagination";

import { useAlert } from "react-alert";
import Typography from "@material-ui/core/Typography";
import MetaData from "../layout/MetaData";



const categories = [
  "All",
  "Audit",
  "Banking",
  "Business Verticals",
  "Fixed Assets",
  "General",
  "Invoicing & Voucher Configuration",
  "Inventory Management",
  "Manufacturing",
  "Office Productivity",
  "Outstanding Management",
  "Reports",
  "Security",
  "Statutory",
  "Voucher Numbering",
  "Work Flow Management"
];

const Products=()=> {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { keyword } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const[category ,setCategory]=useState();
 

  const {
    products,
    loading,
    error,
    productsCount,
    resultPerPage,
    filteredProductsCount,
  } = useSelector((state) => state.products);

  const setCurrentPageNo = (e) => {
    setCurrentPage(e);
  };


  useEffect(() => {
      if(error){
        alert.error(error);
        dispatch(clearErrors());
      }


    dispatch(getProducts(keyword, currentPage,category));
  }, [dispatch, keyword, currentPage,category,alert,error]);

  let count = filteredProductsCount;
 

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
           <MetaData title="PRODUCTS -- TALLY ADDONS"/>
          <h2 className="productsHeading">Products</h2>

          <div className="products">
            {products &&
              products.map((product) => (
                <ProductCard key={product._id} product={product} />
              ))}
          </div>

          <div className="filterBox">

             <Typography >Categories</Typography>
            <ul className="categoryBox">
              {categories.map((category) => (
                <li
                  className="category-link"
                  key={category}
                  onClick={() => setCategory(category)}
                >
                  {category}
                </li>
              ))}
            </ul>
          </div>
          
            <div className="paginationBox">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={resultPerPage}
                totalItemsCount={productsCount}
                onChange={setCurrentPageNo}
                nextPageText="Next"
                prevPageText="Prev"
                hideFirstLastPages={true}
                itemClass="page-item"
                linkClass="page-link"
                activeClass="pageItemActive"
                activeLinkClass="pageLinkActive"
              />
            </div>
          
        </Fragment>
      )}
    </Fragment>
  );
}

export default Products;
