import React, { Fragment, useEffect, useState } from "react";
import CheckoutSteps from "../Cart/CheckoutSteps";
import { useSelector, useDispatch } from "react-redux";
import MetaData from "../layout/MetaData";
import { useAlert } from "react-alert";
import "./ConfirmOrder.css";
import { Link, useHistory,useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";


import { createOrder, clearErrors } from "../../actions/orderAction";
import { addItemsToCart, removeItemsFromCart } from "../../actions/cartAction";
import { useSocket } from "../../SocketProvider";
import { processPayment, validatePayment } from "../../actions/paymentAction";


const ConfirmOrder = () => {
  const { cartItems, error } = useSelector((state) => state.cart);
 
  const { user } = useSelector((state) => state.user);
  const socket = useSocket();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const alert = useAlert();
  const [Durations, setDurations] = useState({});
  const [previousLocation, setPreviousLocation] = useState(null);




  function shallowEqual(objA, objB) {
    if (objA === objB) {
      return true;
    }

    if (
      typeof objA !== "object" ||
      objA === null ||
      typeof objB !== "object" ||
      objB === null
    ) {
      return false;
    }

    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
      return false;
    }

    for (let i = 0; i < keysA.length; i++) {
      if (objA[keysA[i]] !== objB[keysA[i]]) {
        return false;
      }
    }

    return true;
  }

  const deleteCartItems = (id) => {
    dispatch(removeItemsFromCart(id));
  };

  useEffect(() => {
    setPreviousLocation(location);

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    const initialDurations = {};

    cartItems.forEach((item) => {
      initialDurations[item.product] = Durations[item.product] || 1;
    });

    setDurations((prevDurations) => {
      if (!shallowEqual(prevDurations, initialDurations)) {
        return initialDurations;
      }
      return prevDurations;
    });
  }, [cartItems, history, alert, dispatch, error, Durations,location]);


  // Your condition to check cartItems
  if (!cartItems || cartItems.length === 0) {
    if (previousLocation) {
      history.push(previousLocation.pathname + previousLocation.search);
    } else {
      history.push('/'); // Replace with your desired default route
    }
  }

  const updateDurationsAndCartItems = (productId, delta) => {
    setDurations((prevDurations) => {
      const updatedDuration = Math.max(1, prevDurations[productId] + delta);

      // Update cart items with new durations
      const updatedCartItems = cartItems.map((item) => {
        if (item.product === productId) {
          return {
            ...item,
            subtotal: updatedDuration * item.price,
          };
        }
        return item;
      });

      dispatch(addItemsToCart(productId, updatedDuration, updatedCartItems));

      return {
        ...prevDurations,
        [productId]: updatedDuration,
      };
    });
  };

  const increaseDurations = (productId) => {
    if (Durations[productId] >= 3) return;
    updateDurationsAndCartItems(productId, 1);
  };

  const decreaseDurations = (productId) => {
    if (Durations[productId] <= 1) {
      deleteCartItems(productId);
      return;
    }
    updateDurationsAndCartItems(productId, -1);
  };

  const subtotal = cartItems.reduce(
    (acc, item) => acc + Durations[item.product] * item.price,
    0
  );

  const serviceCharges = 0;

  const tax = subtotal * 0.18;

  const totalPrice = subtotal + tax + serviceCharges;

  const paymentData = {
    amount: Math.round(totalPrice * 100),
  };

  const orderDetails = {
    orderItems: cartItems,
    email:user.email,
    itemsPrice: subtotal,
    taxPrice: tax,
    shippingPrice: serviceCharges,
    totalPrice: totalPrice,
  };

  const proceedToPayment = async (e) => {
    try {
      // Dispatch the processPayment action and await the result
      const paymentResponse = await dispatch(processPayment(paymentData));
     
      const options = {
        key: "rzp_test_muJ6kZS5FqyRz5", 
        amount: paymentData.amount,
        currency: "INR",
        name: "Tally Addons",
        description: "Test Transaction",
        image:"https://firebasestorage.googleapis.com/v0/b/tallyaddons-dd5cc.appspot.com/o/logo192.png?alt=media&token=8d26a2eb-fa2a-45df-84b3-67be50fe4ee8",
        order_id: paymentResponse.payment,
        handler: async function (response) {
          const body = {
            ...response,
          };
          
         
          const paymentValidation = await dispatch(validatePayment(body));
          
          if (paymentValidation.validator === true) {
            orderDetails.paymentInfo = {
              id: body.razorpay_payment_id,
              status: "successful",
            };  
            
            await dispatch(createOrder(orderDetails));

            socket.emit("newOrder", orderDetails);
            history.push("/payment/success");
          } else {
            alert.error("Invalid Payment");
            history.push("/payment/failure");
          }
        },
        prefill: {
          name: user.name,
          email: user.email,
          contact: user.phone,
        },
        notes: {
          address: null,
        },
        theme: {
          color: "#ffc927",
        },
      };
      const rzp1 = new window.Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        alert.error(response.error.description);
      });
      rzp1.open();
      e.preventDefault();
    } catch (error) {
      console.error("Error in Processing Payment:", error);
      alert.error("Error in Processing Payment");
    }
  };

  return (
    <Fragment>
      <MetaData title="Confirm Order" />
      <CheckoutSteps />
      <div className="confirmOrderPage">
        <div>
          <div className="confirmshippingArea">
            <Typography>Customer Info</Typography>
            <div className="confirmshippingAreaBox">
              <div>
                <p>Name:</p>
                <span>{user.name}</span>
              </div>
              <div>
                <p>Phone:</p>
                <span>{user.phone}</span>
              </div>
              <div>
                <p>Company:</p>
                <span>{user.company}</span>
              </div>
              <div>
                <p>Tally Serial no:</p>
                <span>{user.tally}</span>
              </div>
            </div>
          </div>
          <div className="confirmCartItems">
            <Typography>Your Cart Items:</Typography>
            <div className="confirmCartItemsContainer">
              {cartItems &&
                cartItems.map((item) => (
                  <div key={item.product}>
                    <img src={item.image} alt="Product" />

                    <div>
                      <Link to={`/product/${item.product}`}>{item.name}</Link>

                      <p onClick={() => deleteCartItems(item.product)}>
                        Remove
                      </p>
                    </div>

                    <div className="detailsBlock-3-1-1">
                      <button onClick={() => decreaseDurations(item.product)}>
                        -
                      </button>
                      <input
                        readOnly
                        type="number"
                        value={Durations[item.product]}
                      />
                      <button onClick={() => increaseDurations(item.product)}>
                        +
                      </button>
                    </div>
                    <span>
                      YR(s) X ₹{item.price} ={" "}
                      <b>₹{item.price * Durations[item.product]}</b>
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/*  */}
        <div>
          <div className="orderSummary">
            <Typography>Order Summary</Typography>
            <div>
              <div>
                <p>Subtotal:</p>
                <span>₹{subtotal}</span>
              </div>
              <div>
                <p>Service Charges:</p>
                <span>₹{serviceCharges}</span>
              </div>
              <div>
                <p>GST:</p>
                <span>₹{tax}</span>
              </div>
            </div>

            <div className="orderSummaryTotal">
              <p>
                <b>Total:</b>
              </p>
              <span>₹{totalPrice}</span>
            </div>

            <button onClick={proceedToPayment}>Proceed To Pay</button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfirmOrder;
