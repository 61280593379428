import React from "react";
import "./AboutUs.css";
import ourvision from "../../../images/OurVision.png";
import aboutus from "../../../images/About-us-header.png";

const AboutUs = () => {
  return (
    <div className="about-us-container">
      <div className="about-us-item">
        <div className="sub-item">
          <div className="sub-item-image">
            <img src={aboutus} alt="About HeaderImage" />
          </div>
          <div className="sub-item-text">
            <h5>
              <a href="/" alt="https://tallyaddons.in">
                <span>Tally Addons</span>
              </a>{" "}
              &nbsp; is a dynamic and innovative leader in the Software industry
              dedicated to providing cutting-edge products and services since
              2013. We pride ourselves on our commitment to excellence, customer
              satisfaction, and a forward-thinking approach that sets us apart
              in the market.
              <br></br>
              <br></br>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;At Tally
              Addons, we believe in the power of innovation, integrity,
              collaboration, which guide our day-to-day operations and
              decision-making processes. Our team is comprised of seasoned
              professionals who bring a wealth of experience and expertise to
              the table, ensuring that our clients receive top-notch solutions
              tailored to their unique needs.
            </h5>
          </div>
        </div>
      </div>
      <div className="about-us-item">
        <h3>Leading provider of Tally solutions for businesses.</h3>
      </div>
      <div className="about-us-item">
        <div className="sub-item-text-2">
          <h5>
            <span>Our Achievements</span>
            <br></br>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Throughout
            our journey, Tally Addons has achieved significant milestones,
            solidifying our position as a respected player in the industry.
            <br></br>
            <br></br>
            Some of our notable accomplishments include
            <br></br>
          </h5>
          <ul>
            <li>
              <p>
                {" "}
                <span>Industry Recognition:</span>
                <br></br>
                Tally Addons has consistently received recognition and accolades
                within the industry for our groundbreaking contributions and
                innovative solutions. Our commitment to pushing boundaries has
                earned us a reputation for excellence.
                <br></br>
                <br></br>
              </p>
            </li>
            <li>
              <p>
                <span>Client Success Stories:</span>
                <br></br>
                The success stories of our clients are a testament to the
                effectiveness of our products/services. We take pride in the
                positive impact we've had on businesses, helping them achieve
                their goals and navigate challenges with confidence.
                <br></br>
                <br></br>
              </p>
            </li>
            <li>
              <p>
                <span>Strategic Partnerships:</span>
                <br></br>
                Tally Addons has forged strategic partnerships with key players
                in the industry, enhancing our capabilities and expanding our
                reach. These collaborations have allowed us to stay at the
                forefront of industry trends and offer cutting-edge solutions to
                our clients.
                <br></br>
                <br></br>
              </p>
            </li>
          </ul>
        </div>
        <div className="sub-item-image">
          <img src={ourvision} alt="About HeaderImage" />
        </div>
      </div>
      <div className="about-us-item">
        {" "}
        <div className="sub-item-text-3">
          <h5>
            At Tally Addons, we remain steadfast in our commitment to delivering
            exceptional value to our clients, <br></br>
            fostering a positive work environment for our employees, and contributing to the betterment of
            the communities in which we operate. As we continue to evolve, our
            focus remains on pushing boundaries, embracing innovation, and
            setting new standards of excellence in the industry.
          </h5>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
